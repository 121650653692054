import React, {useState} from "react"
import {ConfigProvider, Pagination, Table as AntTable} from "antd"

import styles from "./style.module.scss"
import {ChevronLeftIcon, ChevronRightIcon} from "assets"

//TODO rewrite this shit from zero
export default function Table(props: Props) {
    const [current, setCurrent] = useState(1)

    const pageSize = 8

    const getData = (current: number, pageSize: number) => {
        return props.data.slice((current - 1) * pageSize, current * pageSize)
    }

    const processedColumns = props.columns.map(value => {
        return {
            title: <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <img src={value.icon} alt=""/>{value.title}</div>,
            dataIndex: value.dataIndex,
            key: value.key,
            render: value.isDataImage ? (t: any, r: any) => <img style={{width: 30}} src={`${r.avatar}`}
                                                                 alt=""/> : undefined
        }
    })

    return (
        <ConfigProvider theme={{
            components: {
                Pagination: {
                    itemActiveBg: "#262729",
                    colorText: "rgba(247, 247, 247, 0.30)",
                    colorPrimaryHover: "#f7f7f7",
                    colorPrimary: "#f7f7f7",
                    itemSize: 44
                }
            }
        }}>
            <div style={{
                backgroundColor: "#18191C",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                maxHeight: "888px",
                transition: "all 0.25s linear"
            }}>
                <AntTable
                    size={"large"}
                    className={styles.tableAnt}
                    columns={processedColumns}
                    dataSource={getData(current, pageSize)}
                    pagination={false}
                />
                <div className={styles.paginationWrapper}>

                    {/*<div className={styles.paginationButton}*/}
                    {/*     onClick={() => current !== 1 ? setCurrent(current - 1) : current}>*/}
                    {/*    <img src={ChevronLeftIcon} alt=""/>*/}
                    {/*    <span>Back</span>*/}
                    {/*</div>*/}
                    <Pagination
                        onChange={setCurrent}
                        total={props.data.length}
                        current={current}
                        pageSize={pageSize}
                        className={styles.pagination}
                    />
                    {/*<div className={styles.paginationButton}*/}
                    {/*     onClick={() => current !== Math.ceil(props.data.length / pageSize) ? setCurrent(current + 1) : current}>*/}
                    {/*    <span>Next</span>*/}
                    {/*    <img src={ChevronRightIcon} alt=""/>*/}
                    {/*</div>*/}

                </div>
            </div>
        </ConfigProvider>
    )
}

type Props = {
    columns: {
        title: string
        icon: string
        dataIndex: string,
        key: string,
        isDataImage?: boolean
    }[]
    data: {}[]
}
