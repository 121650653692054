import React from "react";

import styles from "./style.module.scss";
import { Input } from "antd";

const StyledInput = (props: Props) => {
  return (
    <Input
      className={styles.input}
      placeholder={props.label}
      value={props.value}
      onChange={e => props.onChange(e.currentTarget.value)}
      suffix={props.icon ? <img src={props.icon} alt="" /> : null}
      disabled={props.disabled}
    />
  );
};

export default StyledInput;

type Props = {
  onChange: (value: string) => void
  value?: string
  label?: string
  icon?: string
  disabled?: boolean
}
