import React from "react"
import styles from "./style.module.scss"
import JustText from "sharedComponents/JustText";

const PortalerCreatorsComponent = () => {
    const data = [
        {
            discordName: "Aaur1s",
            discordIdentifier: "aaur1s",
            avatarURL: "https://cdn.discordapp.com/avatars/323858462532698112/a_7f181dcfc4c580ecb898d0b64a911aa8.gif",
            title: "Lead Portaler Developer",
            description: "Has been the lead developer since August 2022. "
        },
        {
            discordName: "Preeblee",
            discordIdentifier: "preeblee",
            avatarURL: "https://cdn.discordapp.com/avatars/565027170502705152/5fc9dacbe31f6418aa4cd5f1c40edd2c.png",
            title: "Portaler Project Manager",
            description: "Project lead since the original Portaler was abandoned in 2021."
        }
    ]

    return (
        <div>
            <JustText textSize={"big"} isBold={true}>Portaler creators</JustText>
            {data.map((user, index) => {
                return (
                    <div className={styles.userBlockWrapper}>
                        <div className={styles.userBlockElementsWrapper}>
                            <div>
                                <img
                                    src={user.avatarURL}
                                    style={{borderRadius: "50%"}}
                                    height={60}
                                    width={60}
                                    alt=""
                                />
                            </div>
                            <div className={styles.userInfoWrapper}>
                                <span className={`${styles.text} ${styles.username}`}>{user.discordName}</span>
                                <span
                                    className={`${styles.text} ${styles.discriminator}`}>{user.discordIdentifier}</span>
                            </div>
                        </div>
                        <JustText textSize={"medium"} isBold={true}>{user.title}</JustText>
                        <JustText textSize={"medium"} isBold={false}>{user.description}</JustText>
                    </div>
                )
            })}
        </div>
    )
}

export default PortalerCreatorsComponent
