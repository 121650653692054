import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { resetReduxStore } from "redux/hooks";
import { SimpleZone } from "redux/data/simpleZoneSlice";
import { FullZone } from "redux/data/fullZoneSlice";

export type PortalerUser = {
  userId: string
  guildId: string
  memberId: string
  username: string
  nickname: string
  avatarURL: string
  roles: string[]
}

type InitialState = {
  loading: boolean
  firstTimeLoading: boolean
  user?: PortalerUser,
  homeLocation?: FullZone
  enableMapAutoRefresh: boolean
  enableSyncWithPublicMap: boolean
  error: string
}

const initialState: InitialState = {
  loading: false,
  firstTimeLoading: true,
  user: undefined,
  homeLocation: undefined,
  enableMapAutoRefresh: true,
  enableSyncWithPublicMap: true,
  error: ""
};

export const fetchUserInfo = createAsyncThunk("user/fetchUserInfo", async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState

  if (!state.portalerServer.selectedPortalerServer) return rejectWithValue("No server selected");

  const response = await axios
    .get(`/api/user/${state.portalerServer.selectedPortalerServer._id}/me`);
  return response.data;
});

const fullZoneSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setHomeLocation: (state, action: PayloadAction<FullZone | undefined>) => {
      state.homeLocation = action.payload;
    },
    setEnableMapAutoRefresh: (state, action: PayloadAction<boolean>) => {
      state.enableMapAutoRefresh = action.payload;
    },
    setEnableSyncWithPublicMap: (state, action: PayloadAction<boolean>) => {
      state.enableSyncWithPublicMap = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserInfo.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchUserInfo.fulfilled,
      (state, action: PayloadAction<PortalerUser>) => {
        state.loading = false;
        state.firstTimeLoading = false;
        state.user = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.user = undefined;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(resetReduxStore, _ => initialState);
  }
});

export default fullZoneSlice.reducer;
export const {
  setHomeLocation,
  setEnableMapAutoRefresh,
  setEnableSyncWithPublicMap,
} = fullZoneSlice.actions;