import React, {useState} from "react"
import {ShieldIcon} from "assets"
import JustText from "sharedComponents/JustText";

const MistwalkerHallOfFameComponent = () => {
    const [selectedValue, setSelectedValue] = useState("")

    const options = [
        {
            label: "General"
        },
        {
            label: "Weekly"
        }
    ]

    const data = [
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        },
        {
            avatar: "https://i.pinimg.com/originals/7d/7a/e7/7d7ae7757633c72548e70adefc21b8e0.jpg",
            name: "Username",
            points: 10000
        }
    ]

    const rows = [
        {
            label: "Avatar",
            icon: ShieldIcon
        },
        {
            label: "Name",
            icon: ShieldIcon
        },
        {
            label: "Points",
            icon: ShieldIcon
        }
    ]

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "6px"}}>
            <JustText textSize={"big"} isBold={true}>Leaderboard options</JustText>
            {options.map((item) => {
                return (
                    <div/>
                )
            })}
        </div>
    )
}

export default MistwalkerHallOfFameComponent
