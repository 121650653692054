import { SimpleZone } from "redux/data/simpleZoneSlice";
import { useAppSelector } from "redux/hooks";
import React, { MutableRefObject } from "react";
import StyledSelect from "sharedComponents/StyledSelect";


export const ZoneSelect = (props: ZoneSelectProps) => {
  const simpleZones = useAppSelector(state => state.simpleZone.simpleZones);

  return <StyledSelect
    reff={props.reff}
    defaultHint={props.label}
    value={props.value?.name}
    onChange={value => {
      const zone = simpleZones
        .filter(v => !props.exclude?.includes(v))
        .find(v => v.name === value)

      props.onChange?.(zone)
    }}
    onClear={() => props.onChange?.(undefined)}
    data={
      simpleZones
        .filter(v => !props.exclude?.includes(v))
        .map(v => v.name)
    }
    optionsFilter={(value, data) => {
      const wordsValue = value.split(/[\s-]/, 99).map(v => v.toLowerCase())
      const dataString = data?.value?.toString() ?? ""
      const wordsData = dataString.includes("-") ?
        dataString.split("-").map(v => v.toLowerCase()) :
        dataString.split(" ").map(v => v.toLowerCase())

      const validWordsValues: boolean[] = []
      for (const wordValue of wordsValue) {
        let found = false;
        for (const wordData of wordsData) {
          if (wordData.startsWith(wordValue)) {
            found = true;
            break;
          }
        }
        validWordsValues.push(found)
      }

      return validWordsValues.every(v => v);
    }}
    enabled={!props.disabled}
    enableClear
    enableSearch
  />
}

type ZoneSelectProps = {
  reff?: MutableRefObject<any>
  label?: string
  value?: SimpleZone
  onChange?: (value: SimpleZone | undefined) => void
  exclude?: SimpleZone[]
  disabled?: boolean
}