import React from "react";
import styles from "sharedComponents/PortalerLogo/style.module.scss";
import { PortalerLogoIcon } from "assets";
import JustText from "../JustText";
import { appVersion } from "../../index";

const PortalerLogo = () => {
  return (
    <div className={styles.wrapper}>
      <img src={PortalerLogoIcon} alt="" />
      <span style={{ marginLeft: "15px" }}>Portaler</span>
      <span style={{ marginLeft: "5px" }}/>
      <JustText textSize={"small"}>{` v${appVersion}`}</JustText>
    </div>
  );
};

export default PortalerLogo;
