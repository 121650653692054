export const ColorConstants = {
  UNDEFINED: "#37ff00",

  BLUE_ZONE: "#598ABF",
  YELLOW_ZONE: "#FFB000",
  RED_ZONE: "#FF0A00",
  BLACK_ZONE: "#1B1B29",
  AVALON_ZONE: "#36D19D",
  AVALON_DEEP_ZONE: "#260083",

  THETFORD: "#ab2ca2",
  FORT_STERLING: "#FFFFFF",
  LYMHURST: "#809a10",
  BRIDGEWATCH: "#cd733b",
  MARTLOCK: "#0081DD",
  CAERLEON: "#ac2717",
  BRECILIEN: "#ffe000",

  BLUE_CONNECTION: "#5BA5EF",
  YELLOW_CONNECTION: "#F5C254",
  STATIC_TEMP_CONNECTION: "#9F75FF",
  STATIC_CONST_CONNECTION: "#EA4949",

  SELECTED_COLOR: "#FFF",
  // HIGHLIGHTED_COLOR: "#00ff00",
  HOME_COLOR: "#FFA570",
}
