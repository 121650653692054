import React, { useState } from "react";
import { TreeSelect } from "antd";

import JustText from "sharedComponents/JustText";
import UserBlock from "sharedComponents/UserBlock";
import { ArrowRightFromBracketIcon } from "assets";
import StyledButton from "sharedComponents/StyledButton";
import { useSignOut } from "react-auth-kit";
import StyledSelect from "sharedComponents/StyledSelect";
import { resetReduxStore, useAppDispatch, useAppSelector } from "redux/hooks";
import { selectPortalerServerById } from "redux/data/portalerServerSlice";
import { reloadPortalerData } from "App";
import { setEnableMapAutoRefresh, setEnableSyncWithPublicMap, setHomeLocation } from "redux/data/userInfoSlice";
import { ZoneSelect } from "sharedComponents/ZoneSelect";
import TwoStateStyledButton from "sharedComponents/TwoStateStyledButton";
import { setMapForceUpdate } from "redux/data/portalMapSlice";

const { SHOW_PARENT } = TreeSelect;


const SettingsComponent = () => {
  const portalerServers = useAppSelector(state => state.portalerServer.portalerServers)
  const selectedPortalerServer = useAppSelector(state => state.portalerServer.selectedPortalerServer)
  const selectedPortalMap = useAppSelector(state => state.portalMap.selectedPortalMap)
  const fullZones = useAppSelector(state => state.fullZone.fullZones);
  const homeLocation = useAppSelector(state => state.userInfo.homeLocation);
  const enableMapAutoRefresh = useAppSelector(state => state.userInfo.enableMapAutoRefresh)
  const enableSyncWithPublicMap = useAppSelector(state => state.userInfo.enableSyncWithPublicMap)

  const signOut = useSignOut()
  const dispatch = useAppDispatch()

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column", gap: "6px" }}>
      <JustText textSize={"big"} isBold={true}>User Info</JustText>
      <UserBlock/>
      <StyledSelect
        defaultValue={selectedPortalerServer?._id}
        data={portalerServers.map(server => <StyledSelect.Entry label={server.name} value={server._id} icon={server.iconURL}/>)}
        onChange={(value) => {
          dispatch(selectPortalerServerById(value));
          reloadPortalerData(dispatch)
        }}
      />
      <StyledButton
        icon={ArrowRightFromBracketIcon}
        label={"Logout"}
        onClick={() => {
          signOut();
          dispatch(resetReduxStore())
        }}
      />
      <JustText textSize={"big"} isBold={true}>Personal settings</JustText>
      <div style={{ display: "flex", flexDirection: "column", gap: "1px" }}>
        <JustText textSize={"small"} isBold={false}>Your home location</JustText>
        <ZoneSelect
          label={"Home"}
          value={homeLocation}
          onChange={value => fullZones.forEach(fullZone => {
            if (fullZone.name === value?.name) dispatch(setHomeLocation(fullZone));
          })}
        />
      </div>

      <TwoStateStyledButton label={"Enable map auto-refresh"} state={[enableMapAutoRefresh, () => {
        dispatch(setEnableMapAutoRefresh(!enableMapAutoRefresh))
      }]} />
      {selectedPortalMap?.mapType === "PRIVATE_VIP" ?
        <TwoStateStyledButton label={"Enable sync from public map"} state={[enableSyncWithPublicMap, () => {
          dispatch(setEnableSyncWithPublicMap(!enableSyncWithPublicMap))
        }]} />
      : null}



      {/*<SingleSelect label={"Arthur's Rest"} />*/}
      {/*<JustText textSize={"big"} isBold={true}>Zone filter</JustText>*/}
      {/*<SingleSelect label={"Select Zone Type"} />*/}
      {/*<SingleSelect label={"Select Zone Level"} />*/}
      {/*<TreeSelect*/}
      {/*  dropdownRender={(e) => <div>{e}</div>}*/}
      {/*  suffixIcon={*/}
      {/*    opened ? <img src={ChevronUpIcon} alt={""} /> : <img src={ChevronDownIcon} alt={""} />*/}
      {/*  }*/}
      {/*  onDropdownVisibleChange={(status) => setOpened(status)}*/}
      {/*  rootClassName={styles.test}*/}
      {/*  {...tProps}*/}
      {/*  dropdownStyle={{*/}
      {/*    backgroundColor: "#262729"*/}
      {/*  }}*/}
      {/*/>*/}

	    <div style={{height: "100%"}}/>


    </div>
  );
};

export default SettingsComponent;
