import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { resetReduxStore } from "redux/hooks";

export type SimpleZone = {
  _id: string
  name: string
  tier: number
  type: string
  color: string
}

type InitialState = {
  loading: boolean
  firstTimeLoading: boolean
  simpleZones: SimpleZone[]
  error: string
}

const initialState: InitialState = {
  loading: false,
  firstTimeLoading: true,
  simpleZones: [],
  error: ""
};

export const fetchSimpleZones = createAsyncThunk("simpleZone/fetchSimpleZones", async () => {
  const response = await axios
    .get("/api/zone/simple/all");
  return response.data;
});

const simpleZoneSlice = createSlice({
  name: "simpleZone",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSimpleZones.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchSimpleZones.fulfilled,
      (state, action: PayloadAction<SimpleZone[]>) => {
        state.loading = false;
        state.firstTimeLoading = false;
        state.simpleZones = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchSimpleZones.rejected, (state, action) => {
      state.loading = false;
      state.simpleZones = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(resetReduxStore, _ => initialState);
  }
});

export default simpleZoneSlice.reducer;