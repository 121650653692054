import React from "react";

import JustText from "sharedComponents/JustText";
import StyledBlock from "sharedComponents/StyledBlock";
import {
  ExclamationCircleIcon, HouseIcon,
} from "assets";
import { getConnectionIcon, getZoneIcon } from "pages/MainPage/components/PortalMapComponent/styleUtils";
import { ReactSVG } from "react-svg";

const HelpComponent = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "6px", paddingBottom: 50 }}>
      <JustText textSize={"big"} isBold={true}>Help & Map Keys</JustText>
      {/*<WarningMessage>Set your home at settings tab</WarningMessage>*/}

      <StyledBlock groupsOfElements={[["Normal Zones"], [
        getZoneIcon("blue"), getZoneIcon("yellow"),
        getZoneIcon("red"), getZoneIcon("black")
      ]]}/>
      <StyledBlock groupsOfElements={[["City"], [getZoneIcon("city"), getZoneIcon("city-black")]]}/>
      <StyledBlock groupsOfElements={[["Road Zone"], [getZoneIcon("road")]]}/>
      <StyledBlock groupsOfElements={[["Road Hideout Zone"], [getZoneIcon("road-ho")]]}/>
      <StyledBlock groupsOfElements={[["Deep Roads"], [getZoneIcon("road-deep"), getZoneIcon("road-ho-deep")]]}/>
      <StyledBlock groupsOfElements={[["Your Home"], [HouseIcon]]}/>

      <JustText textSize={"big"} isBold={true}>Connections</JustText>

      <JustText textSize={"medium"} prefixIcon={<ReactSVG src={ExclamationCircleIcon} style={{fill: "#EA4949"}}/>}>
        Static Temp connection must have an portal at one end to “stay alive”
      </JustText>
      <JustText textSize={"medium"} prefixIcon={<ReactSVG src={ExclamationCircleIcon} style={{fill: "#EA4949"}}/>}>
        Static Const connections never deletes from map, used to connect cities with surround locs
      </JustText>

      <StyledBlock groupsOfElements={[["7-Person Connection"], [getConnectionIcon("BLUE")]]}/>
      <StyledBlock groupsOfElements={[["20-Person Connection"], [getConnectionIcon("YELLOW")]]}/>
      <StyledBlock groupsOfElements={[["Static Temp Connection"], [getConnectionIcon("STATIC_TEMP")]]}/>
      <StyledBlock groupsOfElements={[["Static Const Connection"], [getConnectionIcon("STATIC_CONST")]]}/>

      <JustText textSize={"big"} isBold={true}>Call Signs & Types</JustText>
      <JustText textSize={"medium"} isBold={false}>
        A call sign is the combination of the first letters of zone type and zone numeric ID, ie: "[R-224]" (Royal 224).
        This is intended for easy voice communication, plus being able to identify a road type at a glance.
      </JustText>

      {/*<JustText textSize={"big"} isBold={true}>Zone/Road Types</JustText>*/}
      {/*<JustText textSize={"medium"} isBold={false}>Each zone has a type associated with it. For Roads, the type*/}
      {/*  can tell*/}
      {/*  you if it's a deep zone, what type of connections it usually has (black or royal), and where on the*/}
      {/*  continent*/}
      {/*  those connections are likely to spawn (high, medium, low, etc).</JustText>*/}
      {/*<JustText textSize={"medium"} isBold={false}>The types do not guarantee that it will only have those types*/}
      {/*  of*/}
      {/*  connections. ie: A "Black Low" could very possibly connect to a Royal zone.</JustText>*/}
      {/*<JustText textSize={"medium"} isBold={false}>However, deep roads and hideout roads will never connect*/}
      {/*  directly to*/}
      {/*  Black or Royal zones.</JustText>*/}


      {/*<JustText textSize={"big"} isBold={true}>Any Questions?</JustText>*/}
      {/*<JustText textSize={"medium"} isBold={false}>If you have any questions feel free to ask them in our discord server</JustText>*/}

    </div>
  );
};

export default HelpComponent;
