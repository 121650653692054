import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { resetReduxStore } from "redux/hooks";

export type FullZone = {
  _id: string
  name: string
  tier: number
  type: string
  color: string
  zoneInfo: ZoneInfo
}

export type ZoneInfo = {
  markers: ZoneMarker[]
  resources: ZoneResource[]
  mobs: ZoneMob[]
}

export type ZoneMarker = {
  name: string
  posX: number
  posY: number
  tiers: string[]
}

export type ZoneResource = {
  name: string
  tier: number
  count: number
}

export type ZoneMob = {
  name: string
  tier: number
  count: number
}

type InitialState = {
  loading: boolean
  firstTimeLoading: boolean
  fullZones: FullZone[]
  error: string
}

const initialState: InitialState = {
  loading: false,
  firstTimeLoading: true,
  fullZones: [],
  error: ""
};

export const fetchFullZones = createAsyncThunk("fullZone/fetchFullZones", async () => {
  const response = await axios
    .get("/api/zone/full/all");
  return response.data;
});

const fullZoneSlice = createSlice({
  name: "fullZone",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFullZones.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchFullZones.fulfilled,
      (state, action: PayloadAction<FullZone[]>) => {
        state.loading = false;
        state.firstTimeLoading = false;
        state.fullZones = action.payload;
        state.error = "";
      }
    );
    builder.addCase(fetchFullZones.rejected, (state, action) => {
      state.loading = false;
      state.fullZones = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(resetReduxStore, _ => initialState);
  }
});

export default fullZoneSlice.reducer;