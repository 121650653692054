import React, { JSX } from "react";
import styles from "./style.module.scss";
import PortalerLogo from "sharedComponents/PortalerLogo";
import { ArrowLeftToLineIcon } from "assets";
import { Tabs } from "antd";
import JustText from "../../../../sharedComponents/JustText";
import { appVersion } from "../../../../index";

const SidebarComponent = (props: Props) => {
  return (
    <div className={styles.background}>
      <div className={`${props.sidebarCollapsed ? styles.contentHidden : styles.sidebarContentWrapper}`}>
        <PortalerLogo />
        <div style={{ height: "100%", paddingBottom: 24 }}>{props.selectedSidebarTab.component}</div>
      </div>

      <div className={styles.sidebarNavigationWrapper}>
        <div className={styles.sidebarButtonWrapper} onClick={() => {
          props.onCollapsedChange();
        }}>
          <img
            src={ArrowLeftToLineIcon}
            style={{
              transform: `rotate(${!props.sidebarCollapsed ? 0 : "180deg"})`,
              transition: `all 0.17s linear`
            }}
            alt=""
          />
        </div>
        <Tabs
          className={styles.sidebarTabs}
          tabPosition={"left"}
          defaultActiveKey={"0"}
          onChange={key => props.onSelectedTabChange(Number(key))}
          content={undefined}
          renderTabBar={(renderTabBarProps, DefaultTabBar) => (
            <DefaultTabBar className={styles.scrollBar} {...renderTabBarProps}>
              {(node) => (
                <div
                  key={`navigationIndex-${node.key}`}
                  className={`${node.key === props.selectedSidebarTab.key ? styles.tabActive : null} ${styles.sidebarButtonWrapper}`}
                  onClick={() => props.onSelectedTabChange(Number(node.key))}
                >
                  <img
                    src={node.key === props.selectedSidebarTab.key ? props.sidebarTabs[Number(node.key)].iconSelected : props.sidebarTabs[Number(node.key)].icon}
                    alt="" />
                  <div
                    className={`${node.key === props.selectedSidebarTab.key ? styles.tabDotActive : null} ${styles.tabDot}`} />
                </div>
              )}
            </DefaultTabBar>
          )}
          items={props.sidebarTabs
            .map(tab => {
              return {
                key: tab.key,
                label: <img src={tab.icon} alt="" />,
                content: <div />
              };
            })}>
        </Tabs>
      </div>
    </div>
  );
};

type Props = {
  onCollapsedChange: () => void
  sidebarCollapsed: boolean
  onSelectedTabChange: (tabIdx: number) => void
  sidebarTabs: SidebarTab[]
  selectedSidebarTab: SidebarTab
}

type SidebarTab = {
  key: string,
  icon: string,
  iconSelected: string,
  component: JSX.Element,
  accessRoles: string[]
  extraElement?: {
    title: string
    component: JSX.Element
  }
}
export default SidebarComponent;