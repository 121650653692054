import React from "react";
import cl from "./style.module.css";
import portalerSmall from "assets/portaler-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";
import { resetReduxStore, useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useSignOut } from "react-auth-kit";

const DiscordInvitePage = () => {
  // const selectedPortalerServer = useAppSelector((state) => state.portalerServer.selectedPortalerServer);
  const signOut = useSignOut()
  const dispatch = useAppDispatch()

  // if (selectedPortalerServer) return <Navigate to="/app" />

  return (
    <div className={cl.backgroundImage}>
      <div className={cl.header}>
        <div className={cl.logoSection}>
          <img src={portalerSmall} />
          <div className={cl.textLogo}>Portaler</div>
        </div>
      </div>
      <div className={cl.contentCenter}>
        <div className={cl.contentText}>
          Looks like you don't have any discord servers with Portaler installed, would you like to join to Portaler
          Community for Public Portaler?
        </div>
        <div className={cl.discordContainer}>
          <div className={cl.discordContainerPortaler}>
            <img src={portalerSmall} width={40} />
          </div>
          <div className={cl.discordContainerText}>
            <div className={cl.discordColumnContainer}>
              <div className={cl.discordHeading}>
                <span className={cl.discordName}>Portaler Community</span>
              </div>
              <div className={cl.discordServerStatus}>
                <div className={cl.discordOnline}>
                  <FontAwesomeIcon style={{ fontSize: "6px", color: "#3AA93F" }} icon={faCircle} />
                  <span className={cl.discordOnline}>1572 Online</span>
                </div>
                <div className={cl.discordOnline}>
                  <FontAwesomeIcon style={{ fontSize: "6px", color: "#656565" }} icon={faCircle} />
                  <span className={cl.discordOnline}>4100 Members</span>
                </div>
              </div>
            </div>
            <div className={cl.discordColumnButton}>
              <Button type="danger" className={cl.discordJoinButton} onClick={
                () => window.open("https://discord.gg/hbupFJ3AAz", "_blank")
              }>
                <span className={cl.discordTextButton}>Join</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Button className={cl.button} type="link" onClick={() => {
        signOut()
        dispatch(resetReduxStore())
      }}>
        <span className={cl.buttonText}>Log out</span>
      </Button>
    </div>
  );
};

export default DiscordInvitePage;
