import React from "react"

import { CheckIcon, MarkFilledIcon } from "assets";
import StyledButton from "sharedComponents/StyledButton";

const TwoStateStyledButton = (props: Props) => {
  return (
    <StyledButton
      onClick={() => {
        props.state[1](!props.state[0])
      }}
      icon={props.state[0] ? CheckIcon : MarkFilledIcon}
      label={props.label}
      backgroundColor={"#262729"}
    />
  )
}

type Props = {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  label: string
}

export default TwoStateStyledButton