import { ZoneMarker } from "../../redux/data/fullZoneSlice";
import { Image, Tooltip } from "antd";
import {
  MinimapMarkerChestGroup,
  MinimapMarkerChestRaid,
  MinimapMarkerChestSolo,
  MinimapMarkerDngGroup,
  MinimapMarkerDngRaid, MinimapMarkerDngSolo,
  MinimapMarkerFiber,
  MinimapMarkerHide,
  MinimapMarkerOre,
  MinimapMarkerStone,
  MinimapMarkerWood,
  NoDataMap
} from "assets";
import { useEffect, useRef, useState } from "react";
import JustText from "../JustText";

const ZoneMarkerMapComponent = (props: ZoneMarkerMapComponentProps) => {
  const getIcon = (name: string): string => {
    switch (name) {
      case "Big Group Chest": return MinimapMarkerChestGroup
      case "Avalonian Chest": return MinimapMarkerChestRaid
      case "Big Avalonian Chest": return MinimapMarkerChestRaid
      case "Solo Chest": return MinimapMarkerChestSolo
      case "Big Solo Chest": return MinimapMarkerChestSolo
      case "Solo Dungeon": return MinimapMarkerDngSolo
      case "Group Dungeon": return MinimapMarkerDngGroup
      case "Avalonian Dungeon": return MinimapMarkerDngRaid
      case "Fiber": return MinimapMarkerFiber
      case "Hide": return MinimapMarkerHide
      case "Ore": return MinimapMarkerOre
      case "Stone": return MinimapMarkerStone
      case "Wood": return MinimapMarkerWood
      default: return ""
    }
  }
  const getSize = (name: string): number => {
    switch (name) {
      case "Big Group Chest": return 15
      case "Big Avalonian Chest": return 15
      case "Big Solo Chest": return 15
      case "Solo Dungeon": return 15
      case "Group Dungeon": return 15
      case "Avalonian Dungeon": return 15
      default: return 10
    }
  }

  const markerWidthPercent = getSize(props.marker.name)

  const markerImg = <Image
    src={getIcon(props.marker.name)}
    alt={props.marker.name}
    width={"100%"}
    style={{
      position: "absolute",
      // backgroundColor: "red",
      aspectRatio: 1
    }}
    preview={false}
    onError={() => {
    }}
  />

  return <div style={{
    position: "absolute",
    top: `${Math.round((props.marker.posY * (props.mapSize / 1024)) / (props.mapSize / 100)) - markerWidthPercent}%`,
    left: `${Math.round((props.marker.posX * (props.mapSize / 1024)) / (props.mapSize / 100)) - (markerWidthPercent/2)}%`,
    // objectPosition: `${(xPos / (props.mapSize / 100)).toPrecision(2)}% ${(yPos / (props.mapSize / 100)).toPrecision(2)}%`,
    width: `${markerWidthPercent}%`,
    aspectRatio: 1
  }}>
    <Tooltip
      title={<div>
        <JustText textSize={"medium"} isBold={true}>{props.marker.name}</JustText>
        <JustText textSize={"small"}>{`Position: [${props.marker.posX}, ${props.marker.posY}]`}</JustText>
        <JustText textSize={"small"}>{`Tiers: [${(props.marker.tiers.length == 0 ? ["Undefined"] : props.marker.tiers).join(", ")}]`}</JustText>
      </div>}
    >
      {markerImg}
    </Tooltip>
  </div>;
}

export const ZoneMap = (props: ZoneMapProps) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null)
  const [mapSize, setMapSize] = useState<number>()
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false)

  useEffect(() => {
    setMapSize(imageContainerRef.current?.offsetWidth)
  }, [])

  return <div
    style={{
      width: "100%",
      height: "100%",
      aspectRatio: 1,
      position: "relative"
    }}
  >
    <Image
      style={{
        position: "absolute",
        top: 0,
        zIndex: 0
      }}
      width={"100%"}
      height={"100%"}
      src={`/api/zone/image/${props.zoneId}`}
      alt={"zone map image"}
      fallback={NoDataMap}
      preview={false}
      onError={() => setImageLoaded(false)}
      onLoad={event => {
        // @ts-ignore
        setImageLoaded(!event.target.src.includes("no-data-map"));
      }}
    />
    <div
      ref={imageContainerRef}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        zIndex: 2
      }}
    >
      {mapSize && isImageLoaded ? props.markers.map((marker, idx) =>
         <ZoneMarkerMapComponent
          key={`zoneMarkerMap-${idx}`}
          marker={marker}
          mapSize={mapSize}
        />
      ) : null}
    </div>
  </div>
}

type ZoneMapProps = {
  zoneId: string,
  markers: ZoneMarker[]
}

type ZoneMarkerMapComponentProps = {
  marker: ZoneMarker,
  mapSize: number
}