import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { resetReduxStore } from "redux/hooks";

type PortalerServer = {
  _id: string,
  name: string,
  iconURL?: string,
  isEnabled: boolean,
  memberIds: string[]
}

type InitialState = {
  loading: boolean
  firstTimeLoading: boolean
  portalerServers: PortalerServer[]
  selectedPortalerServer?: PortalerServer
  error: string
}

const initialState: InitialState = {
  loading: false,
  firstTimeLoading: true,
  portalerServers: [],
  selectedPortalerServer: undefined,
  error: ""
};

export const fetchPortalerServers = createAsyncThunk("server/fetchPortalerServers", async () => {
  const response = await axios
    .get("/api/server/list");
  return response.data;
});

const portalerServerSlice = createSlice({
  name: "server",
  initialState,
  reducers: {
    selectPortalerServerById: (state, action: PayloadAction<string>) => {
      state.selectedPortalerServer = state.portalerServers.find(server => server._id === action.payload);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPortalerServers.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchPortalerServers.fulfilled,
      (state, action: PayloadAction<PortalerServer[]>) => {
        state.loading = false;
        state.firstTimeLoading = false;

        state.portalerServers = action.payload;

        if (!state.selectedPortalerServer) {
          const portalerServersIds = action.payload.map(server => server._id);
          state.selectedPortalerServer = state.portalerServers.length === 0 ? undefined :
            portalerServersIds.includes("923232726377725972") ? state.portalerServers[portalerServersIds.indexOf("923232726377725972")] :
              state.portalerServers[0];
        } else {
          const serverFromReq = state.portalerServers.find(v => v._id === state.selectedPortalerServer!!._id)!!;
          if (JSON.stringify(serverFromReq) !== JSON.stringify(state.selectedPortalerServer)) state.selectedPortalerServer = serverFromReq;
        }

        state.error = "";
      }
    );
    builder.addCase(fetchPortalerServers.rejected, (state, action) => {
      state.loading = false;
      state.firstTimeLoading = false;
      state.portalerServers = [];
      state.selectedPortalerServer = undefined;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(resetReduxStore, _ => initialState);
  }
});

export default portalerServerSlice.reducer;
export const { selectPortalerServerById } = portalerServerSlice.actions;
