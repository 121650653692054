import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { resetReduxStore } from "../hooks";
import { appVersion } from "../../index";

type InitialState = {
  appVersion?: string
  isBackendUp?: boolean | null | undefined
}

const initialState: InitialState = {};

export const fetchIsBackendUp = createAsyncThunk("appGlobal/fetchIsBackendUp", async () => {
  return await axios.get("/api/auth/login")
    .then(res => res.status === 200)
    .catch(() => false)
});

const appGlobalSlice = createSlice({
  name: "appGlobal",
  initialState,
  reducers: {
    setAppVersion: (state, action: PayloadAction<string>) => {
      state.appVersion = action.payload;
    },
    setIsBackendUp: (state, action: PayloadAction<boolean>) => {
      state.isBackendUp = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchIsBackendUp.pending, state => {
      state.isBackendUp = null
    });
    builder.addCase(fetchIsBackendUp.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isBackendUp = action.payload;
    });
    builder.addCase(fetchIsBackendUp.rejected, (state, action) => {
      state.isBackendUp = false
    });
    builder.addCase(resetReduxStore, state => {
      state.isBackendUp = undefined
      return state
    });
  }
});

export default appGlobalSlice.reducer;
export const {
  setAppVersion,
  setIsBackendUp,
} = appGlobalSlice.actions;