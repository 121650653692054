import React, {useState} from "react"
import {LocationBlackIcon, ShieldIcon} from "assets"
import JustText from "sharedComponents/JustText";

const HideoutZonesComponent = () => {
    const [selectedValue, setSelectedValue] = useState("")

    const options = [
        {
            label: "All"
        },
        {
            label: "West"
        },
        {
            label: "East"
        }
    ]

    const data = [
        {
            name: "test",
            owner: "uristri"
        }
    ]

    const rows = [
        {
            label: "Zone Name",
            icon: LocationBlackIcon
        },
        {
            label: "Owner",
            icon: ShieldIcon
        }
    ]

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "6px"}}>
            <JustText textSize={"big"} isBold={true}>Hideout zones options</JustText>
            {options.map((item, index) => {
                return (
                    <div/>
                )
            })}
        </div>
    )
}

export default HideoutZonesComponent
