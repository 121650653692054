import cytoscape, { CytoscapeOptions, ElementDefinition } from "cytoscape";
import fcose from "cytoscape-fcose";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./style.module.scss";
import {
  getCityColor,
  getEdgeColor,
  getNodeColor,
  getNodeShape,
  getNodeTierIcon
} from "pages/MainPage/components/PortalMapComponent/styleUtils";
import { ColorConstants } from "./colorConstants";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCenterOnZone, setMapForceUpdate, setSelectedFullZone, setSelectedPortalConnection } from "redux/data/portalMapSlice";
import { SimpleZone } from "redux/data/simpleZoneSlice";
import JustText from "sharedComponents/JustText";

cytoscape.use(fcose);

const PortalMapComponent = () => {
  const fullZonesList = useAppSelector(state => state.fullZone.fullZones)
  const portalMaps = useAppSelector(state => state.portalMap.portalMaps)
  const portalMap = useAppSelector(state => state.portalMap.selectedPortalMap)
  const portalerServer = useAppSelector(state => state.portalerServer.selectedPortalerServer)
  const mapMarkedForUpdate = useAppSelector(state => state.portalMap.portalMapForceUpdate)
  const centerOnZone = useAppSelector(state => state.portalMap.centerOnZone)
  const homeLocation = useAppSelector(state => state.userInfo.homeLocation)

  const dispatch = useAppDispatch()

  const callSign = (zone: SimpleZone): string => {
    if (zone.type.startsWith('TUNNEL') && zone._id) {
      const callSignArr = zone.type.split('_').slice(1)
      const aid = zone._id.split('-')[1]

      const callSign = callSignArr.map((val) => val[0]).join('')

      return `${callSign}-${aid}`
    }
    return ''
  }


  const cyElements = useMemo<ElementDefinition[]>(() => {
    const elementsToAdd: ElementDefinition[] = [];
    if (portalMap === undefined) return elementsToAdd;
    const createNode = (simpleZone: SimpleZone): ElementDefinition => {
      const isCity = simpleZone.color.startsWith("city")
      const isDeep = simpleZone.type.includes("DEEP")
      const isHome = simpleZone._id === homeLocation?._id
      const callSignTxt = callSign(simpleZone)
      return {
        group: "nodes",
        style: {
          "background-image": [
            getNodeTierIcon(simpleZone)
          ],
          shape: getNodeShape(simpleZone)[0],
          width: getNodeShape(simpleZone)[1],
          height: getNodeShape(simpleZone)[2],
          backgroundColor: getNodeColor(simpleZone.color),

          "background-fill": isCity || isDeep ? "linear-gradient" : "solid",
          "background-gradient-stop-colors":
            isCity ? `${getNodeColor(simpleZone.color)} ${getCityColor(simpleZone)}` :
              isDeep ? `${getNodeColor(simpleZone.color)} ${ColorConstants.AVALON_DEEP_ZONE}`
                : undefined,
          "background-gradient-stop-positions":
            isCity ? "30% 70%" :
              isDeep ? "30% 70%" :
                undefined,
          "background-gradient-direction": isCity || isDeep ? "to-right" : undefined
        },
        data: {
          id: simpleZone._id,
          label: `${simpleZone.name} ${callSignTxt !== "" ? `[${callSign(simpleZone)}]` : ""}`,
        },
        classes: [isHome ? "homeNode" : ""]
      }
    }
    portalMap.portalConnections.forEach(portalConnection => {
      elementsToAdd.push(
        createNode(portalConnection.info.fromZone),
        createNode(portalConnection.info.toZone)
      );
    });
    portalMap.portalConnections.forEach(portalConnection => {
      let alreadyExistsCount = 0;
      const isLooped = portalConnection.info.fromZone._id === portalConnection.info.toZone._id;
      let loopedCount = 0;

      const timeLeftMilliseconds = dayjs(portalConnection.info.expiringDate).diff(dayjs())
      const hours = ~~(timeLeftMilliseconds / 3600000)
      const minutes = ~~(timeLeftMilliseconds % 3600000 / 60000)
      const timeString = `${hours}h ${minutes}m`// ${~~(timeLeftMilliseconds % 60000 / 1000)}s
      const isStatic = portalConnection.info.portalType.startsWith("STATIC")

      elementsToAdd.forEach(value => {
        if (
          (value.data.source === portalConnection.info.fromZone._id &&
            value.data.target === portalConnection.info.toZone._id) ||
          (value.data.target === portalConnection.info.fromZone._id &&
            value.data.source === portalConnection.info.toZone._id)
        ) alreadyExistsCount++;
        if (
          (value.data.source === portalConnection.info.fromZone._id &&
            value.data.target === portalConnection.info.fromZone._id) ||
          (value.data.target === portalConnection.info.toZone._id &&
            value.data.source === portalConnection.info.toZone._id)
        ) loopedCount++;
      });
      elementsToAdd.push({
        group: "edges",
        style: {
          lineColor: getEdgeColor(portalConnection.info.portalType),
          "control-point-distances": [60 + (alreadyExistsCount * 40), -60 + (alreadyExistsCount * 40)],
          "control-point-weights": [0.2, 0.75],
          "loop-sweep": "60deg",
          "loop-direction": `${-45 + (loopedCount * 60)}deg`,
          "font-size": isLooped ? 10 : 20,
          "line-gradient-stop-colors": [ColorConstants.SELECTED_COLOR, getEdgeColor(portalConnection.info.portalType), ColorConstants.SELECTED_COLOR],
          "line-gradient-stop-positions": [10, 40, 90],
        },
        data: {
          id: portalConnection.portalId,
          source: portalConnection.info.fromZone._id,
          target: portalConnection.info.toZone._id,
          label: (isStatic ? "" : timeString)// + " " + `(${portalConnection.portalId})`
        }
      });
    });

    if (homeLocation && !elementsToAdd.find(value => value.data.id === homeLocation._id)) {
      elementsToAdd.push(createNode(homeLocation))
    }

    return elementsToAdd;
  }, [portalMap?.portalConnections, homeLocation]);

  const cyContainer = useRef<HTMLDivElement | null>(null);

  const cySettings = useCallback((cytoscapeContainer: HTMLDivElement | null): CytoscapeOptions => {
    return {
      container: cytoscapeContainer,
      pan: { x: 0, y: 0 },
      minZoom: 0.05,
      maxZoom: 1.75,
      wheelSensitivity: 0.25,
      zoomingEnabled: true,
      userZoomingEnabled: true,
      panningEnabled: true,
      userPanningEnabled: true,
      boxSelectionEnabled: true,
      selectionType: "single",
      layout: {
        // @ts-ignore
        name: "fcose",
        nodeDimensionsIncludeLabels: true,
        // @ts-ignore
        idealEdgeLength: 150,
        // @ts-ignore
        nestingFactor: 0.5,
        // @ts-ignore
        fit: true,
        // @ts-ignore
        randomize: true,
        // @ts-ignore
        padding: 42,
        // @ts-ignore
        animationDuration: 250,
        // @ts-ignore
        tilingPaddingVertical: 20,
        // @ts-ignore
        tilingPaddingHorizontal: 20,
        // @ts-ignore
        nodeRepulsion: 4194304,
        // @ts-ignore
        numIter: 2097152,
        uniformNodeDimensions: true,
        quality: "proof",
        gravityRangeCompound: -100.0
      },
      style: [
        {
          selector: "nodes",
          style: {
            "label": "data(label)",

            //Node Border
            "border-color": "white",
            "border-width": 1,

            //Node Background
            "background-width": "35 35",
            "background-height": "35 35",
            "background-clip": "none",
            "bounds-expansion": "10 10",
            "background-opacity": 1,

            //text
            color: "white",
            "text-outline-color": "#222",
            "text-outline-width": 2,
            "text-outline-opacity": 0.5,
            "font-size": 20,
          }
        },
        {
          selector: "edges",
          style: {
            "label": "data(label)",

            //Edge style
            width: 5,
            "curve-style": "unbundled-bezier",
            "source-endpoint": "inside-to-node",
            "target-endpoint": "inside-to-node",

            //text
            color: "white",
            "text-outline-color": "#222",
            "text-outline-width": 2,
            "text-outline-opacity": 0.5,
            "text-wrap": "wrap",
            "text-max-width": "200px",

            //@ts-ignore
            "edge-text-rotation": "autorotate"
          }
        },
        {
          selector: ".selectedNode",
          style: {
            "border-color": ColorConstants.SELECTED_COLOR,
            "border-width": 5
          }
        },
        // {
        //   selector: ".highlightedNode",
        //   style: {
        //     "border-color": ColorConstants.HIGHLIGHTED_COLOR,
        //     "border-width": 5
        //   }
        // },
        {
          selector: ".selectedEdge",
          style: {
            "line-fill": "linear-gradient",
          }
        },
        {
          selector: ".dyingEdge",
          style: {
            "color": "red",
          }
        },
        {
          selector: ".homeNode",
          style: {
            "border-color": ColorConstants.HOME_COLOR,
            "border-width": 5
          }
        }
      ]
    };
  }, []);

  cytoscape.use(fcose);
  cytoscape.warnings(false)

  const cy = useRef<cytoscape.Core>();

  const enableMapAutoRefresh = useAppSelector(state => state.userInfo.enableMapAutoRefresh)
  const isFirstRender = useRef(true);

  const reloadCyLayout = useCallback(() => {
    cy.current = cytoscape(cySettings(cyContainer.current));
    cy.current.add(cyElements);

    cy.current.on("click tap", "node", function(event) {
      fullZonesList.forEach(fullZone => {
        if (fullZone._id === event.target.id()) {
          cy.current?.nodes().removeClass("selectedNode")
          cy.current?.edges().removeClass("selectedEdge")
          event.target.addClass("selectedNode")
          dispatch(setSelectedFullZone(fullZone))
          dispatch(setSelectedPortalConnection(undefined))
        }
      })
    });
    cy.current.on("click tap", "edge", function(event) {
      portalMap?.portalConnections.forEach(pC => {
        if (pC.portalId === event.target.id()) {
          cy.current?.edges().removeClass("selectedEdge")
          cy.current?.nodes().removeClass("selectedNode")
          event.target.addClass("selectedEdge")
          event.target.source().addClass("selectedNode")
          event.target.target().addClass("selectedNode")

          dispatch(setSelectedPortalConnection(pC))
          dispatch(setSelectedFullZone(undefined))
        }
      })
    });
    cy.current.on("click tap", (event) => {
      if (event.target === cy.current) {
        cy.current?.edges().removeClass("selectedEdge")
        cy.current?.nodes().removeClass("selectedNode")
        dispatch(setSelectedPortalConnection(undefined))
        dispatch(setSelectedFullZone(undefined))
      }
    });

    cy.current.layout(cySettings(cyContainer.current).layout as cytoscape.LayoutOptions).run();
  }, [cyElements, cySettings, portalMap, enableMapAutoRefresh, mapMarkedForUpdate, isFirstRender]);

  useEffect(() => {
    if (portalMap && (isFirstRender.current || enableMapAutoRefresh || mapMarkedForUpdate)) {
      reloadCyLayout();
      isFirstRender.current = false
      dispatch(setMapForceUpdate(false));
    }
  }, [cyContainer, cyElements, cySettings, reloadCyLayout, portalMap, mapMarkedForUpdate]);

  useEffect(() => {
    if (centerOnZone) {
      const cyNode = cy.current?.nodes().$id(centerOnZone._id)
      if (cyNode && cy.current) {
        cy.current.zoom({ level: 1.7, position: cyNode.position() }).center(cyNode);
        // cyNode?.addClass("highlightedNode");
        // setTimeout(() => {
        //   cyNode?.removeClass("highlightedNode");
        // }, 1500)
      }
      dispatch(setCenterOnZone(undefined));
    }
  }, [centerOnZone]);

  const timersUpdateIntervalRef = useRef<number>()

  useEffect(() => {
    if (timersUpdateIntervalRef.current) clearInterval(timersUpdateIntervalRef.current)

    timersUpdateIntervalRef.current = setInterval(function f() {
      cy.current?.edges().forEach(edge => {
        const portalConnection = portalMap?.portalConnections.find(pC => pC.portalId === edge.id())
        const timeLeftMilliseconds = dayjs(portalConnection?.info.expiringDate).diff(dayjs())
        const hours = ~~(timeLeftMilliseconds / 3600000)
        const minutes = ~~(timeLeftMilliseconds % 3600000 / 60000)
        const timeString = `${hours}h ${minutes}m`// ${~~(timeLeftMilliseconds % 60000 / 1000)}s
        const isStatic = edge.data("label") === ""
        if (!isStatic) {
          if (hours == 0) {
            edge.addClass("dyingEdge");
          } else {
            edge.removeClass("dyingEdge");
          }
          edge.data("label", timeString)
        }
      })
      return f
    }(), 3000, [])

    return () => {
      cy.current?.destroy();
      clearInterval(timersUpdateIntervalRef.current)
    };
  }, [portalMap]);


  if (portalMaps.length > 0) {
    return <div className={styles.mapContainer}>
      <div className={styles.mapGraph} ref={cyContainer}/>
      <div className={styles.mapInfo}>
        <JustText textSize={"medium"} isBold={false}>
          {(portalerServer?.name ?? "No server selected") + ", " + (portalMap?.name ?? "No map selected")}
        </JustText>
      </div>
    </div>;
  } else {
    cy.current?.destroy()
    return <div className={styles.mapGraph} style={{display: "flex", alignItems: "center", justifyContent: "center" }}>
      <JustText textSize={"big"} isBold={false}>No maps available</JustText>
    </div>
  }
};

export default PortalMapComponent;