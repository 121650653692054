import React, { useEffect, useState } from "react";
import {
	LocationPinIcon,
} from "assets"
import StyledSelect from "sharedComponents/StyledSelect"
import JustText from "sharedComponents/JustText";
import StyledBlock from "sharedComponents/StyledBlock";
import { setSelectedFullZone } from "redux/data/portalMapSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
	getNodeColor,
	getNodeIcon,
	getZoneIcon,
	getTierRoman, getZoneType
} from "pages/MainPage/components/PortalMapComponent/styleUtils";
import { ZoneSelect } from "sharedComponents/ZoneSelect";
import { ZoneMap } from "../../../../../../sharedComponents/ZoneMap";
import { ZoneMob, ZoneResource } from "../../../../../../redux/data/fullZoneSlice";
import { Collapse } from "antd";

const InformationComponent = () => {
	const fullZonesList = useAppSelector(state => state.fullZone.fullZones)
	const selectedFullZone = useAppSelector(state => state.portalMap.selectedFullZone)

	const dispatch = useAppDispatch()

	useEffect(() => {
		fullZonesList.forEach(fullZone => {
			if (selectedFullZone?.name === fullZone.name) dispatch(setSelectedFullZone(fullZone))
		})
	}, [])

	return (
		<div style={{ display: "flex", gap: "6px", flexDirection: "column" }}>
			<JustText textSize={"big"} isBold={true}>Location</JustText>
			<ZoneSelect
				label={"Select Zone"}
				value={selectedFullZone}
        onChange={simpleZone => {
					if (simpleZone === undefined) {
						dispatch(setSelectedFullZone(undefined));
						return;
					}
	        fullZonesList.forEach(fullZone => {
						if (simpleZone.name === fullZone.name) dispatch(setSelectedFullZone(fullZone))
	        })
        }}
			/>

			{selectedFullZone ? <>
				<div style={{display: "flex", alignItems: "center", gap: "5%"}}>
					<StyledBlock groupsOfElements={[
						[getZoneIcon(selectedFullZone.color), getZoneType(selectedFullZone), getTierRoman(selectedFullZone.tier)]
					]}/>
				</div>
				<JustText textSize={"big"} isBold={true}>Zone Map</JustText>
				<ZoneMap zoneId={selectedFullZone._id} markers={selectedFullZone.zoneInfo.markers}/>

				<Collapse items={[{
					key: "1", label: "Unordered Resources", children:
						<>
							{infoCounter(selectedFullZone.zoneInfo.resources).map(info => {
								return <JustText textSize={"medium"} isBold={false}>{info}</JustText>;
							})}
						</>
				}]} />
				<Collapse items={[{
					key: "1", label: "Unordered Mobs", children:
						<>
							{infoCounter(selectedFullZone.zoneInfo.mobs).map(info => {
								return <JustText textSize={"small"} isBold={false}>{info}</JustText>;
							})}
						</>
				}]} />
			</> : null}
		</div>
	)
}
const infoCounter = (info: ZoneResource[] | ZoneMob[] | null | undefined): string[] => {
	if (!info) {
		return []
	}

	const tierArr: { [key: string]: number[] } = {}

	info.forEach((zoneInf) => {
		const name = zoneInf.name.split("_").join(" ")
			.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

		if (tierArr[name]) {
			tierArr[name].push(zoneInf.tier)
		} else {
			tierArr[name] = [zoneInf.tier]
		}
	})

	return Object.keys(tierArr).map((infoName) => {
		tierArr[infoName].sort()

		const range =
			tierArr[infoName].length > 1
				? `${getTierRoman(tierArr[infoName][0])} - ${getTierRoman(
					tierArr[infoName][tierArr[infoName].length - 1]
				)}`
				: getTierRoman(tierArr[infoName][0])

		return `${infoName}: ${range}`
	})
}
export default InformationComponent
