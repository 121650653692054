import {
  AlbionTier1Icon,
  AlbionTier2Icon,
  AlbionTier3Icon,
  AlbionTier4Icon,
  AlbionTier5Icon,
  AlbionTier6Icon,
  AlbionTier7Icon,
  AlbionTier8Icon, CircleFilledIcon,
  HouseIcon,
  LineFilledIcon,
  RombIcon,
  SquareIcon,
  StarIcon,
  TriangleIcon
} from "assets";
import { Css } from "cytoscape";
import { ColorConstants } from "pages/MainPage/components/PortalMapComponent/colorConstants";
import { SimpleZone } from "redux/data/simpleZoneSlice";
import { PortalConnection, PortalType } from "redux/data/portalMapSlice";
import React, { ReactElement } from "react";
import { ReactSVG } from "react-svg";

//Nodes style variant 1
export const getNodeShape = (simpleZone: SimpleZone): [Css.NodeShape, number, number] => {
  switch (simpleZone.color) {
    case "blue":
      return ["ellipse", 35, 35];
    case "yellow":
      return ["ellipse", 35, 35];
    case "red":
      return ["ellipse", 35, 35];
    case "black":
      return ["ellipse", 35, 35];
    case "city":
      return ["star", 45, 45];
    case "city-black":
      return ["star", 45, 45];
    case "road":
      return ["round-diamond", 45, 45];
    case "road-ho":
      return ["round-rectangle", 35, 35];
    default:
      return ["vee", 40, 40];
  }
};
export const getNodeColor = (simpleZoneColor: string): string => {
  switch (simpleZoneColor) {
    case "blue":
      return ColorConstants.BLUE_ZONE;
    case "yellow":
      return ColorConstants.YELLOW_ZONE;
    case "red":
      return ColorConstants.RED_ZONE;
    case "black":
      return ColorConstants.BLACK_ZONE;
    case "city":
      return ColorConstants.BLUE_ZONE;
    case "city-black":
      return ColorConstants.BLACK_ZONE;
    case "road":
      return ColorConstants.AVALON_ZONE;
    case "road-ho":
      return ColorConstants.AVALON_ZONE;
    case "road-deep":
      return ColorConstants.AVALON_DEEP_ZONE;
    case "road-ho-deep":
      return ColorConstants.AVALON_DEEP_ZONE;
    default:
      return ColorConstants.UNDEFINED;
  }
};
export const getCityColor = (simpleZone: SimpleZone): string => {
  if (simpleZone.name.startsWith("Thetford")) return  ColorConstants.THETFORD;
  if (simpleZone.name.startsWith("Fort Sterling")) return  ColorConstants.FORT_STERLING;
  if (simpleZone.name.startsWith("Lymhurst")) return  ColorConstants.LYMHURST;
  if (simpleZone.name.startsWith("Bridgewatch")) return  ColorConstants.BRIDGEWATCH;
  if (simpleZone.name.startsWith("Martlock")) return  ColorConstants.MARTLOCK;
  if (simpleZone.name.startsWith("Caerleon")) return  ColorConstants.CAERLEON;
  if (simpleZone.name.startsWith("Brecilien")) return  ColorConstants.BRECILIEN;
  if (simpleZone.name.includes("Rest")) return  ColorConstants.BLACK_ZONE;
  return ColorConstants.UNDEFINED
};

//Nodes style variant 2
export const getNodeIcon = (simpleZoneColor: string) => {
  switch (simpleZoneColor) {
    case "blue":
      return CircleFilledIcon;
    case "yellow":
      return CircleFilledIcon;
    case "red":
      return CircleFilledIcon;
    case "black":
      return CircleFilledIcon;
    case "city":
      return StarIcon;
    case "city-black":
      return StarIcon;
    case "road":
      return RombIcon;
    case "road-ho":
      return SquareIcon;
    case "road-deep":
      return RombIcon;
    case "road-ho-deep":
      return SquareIcon;
    default:
      return HouseIcon;
  }
};
export const getNodeTierIcon = (simpleZone: SimpleZone): string => {
  switch (simpleZone.tier) {
    case 1:
      return AlbionTier1Icon;
    case 2:
      return AlbionTier2Icon;
    case 3:
      return AlbionTier3Icon;
    case 4:
      return AlbionTier4Icon;
    case 5:
      return AlbionTier5Icon;
    case 6:
      return AlbionTier6Icon;
    case 7:
      return AlbionTier7Icon;
    case 8:
      return AlbionTier8Icon;
    default:
      return "";
  }
};

export const getTierRoman = (tier: number): string => {
  switch (tier) {
    case 1:
      return "I";
    case 2:
      return "II";
    case 3:
      return "III";
    case 4:
      return "IV";
    case 5:
      return "V";
    case 6:
      return "VI";
    case 7:
      return "VII";
    case 8:
      return "VIII";
    default:
      return "";
  }
};

export const getZoneType = (simpleZone: SimpleZone): string => {
  const blacklist = ["openpvp", "tunnel", "nofurniture", "01", "1", "2", "3", "4", "5", "6"]
  return simpleZone.type.toLowerCase().split("_")
    .filter(elem => !blacklist.includes(elem))
    .filter((elem, idx, array) => !(array.includes("portalcity") && elem === "playercity"))
    .join(" ")
};

// Edge style
export const getEdgeColor = (portalType: PortalType): string => {
  switch (portalType) {
    case "BLUE":
      return ColorConstants.BLUE_CONNECTION;
    case "YELLOW":
      return ColorConstants.YELLOW_CONNECTION;
    case "STATIC_TEMP":
      return ColorConstants.STATIC_TEMP_CONNECTION;
    case "STATIC_CONST":
      return ColorConstants.STATIC_CONST_CONNECTION;
    default:
      return ColorConstants.UNDEFINED;
  }
};

export const getZoneIcon = (simpleZoneColor: string): ReactElement => {
  // let extraStyle: React.CSSProperties = {}
  // if (simpleZoneColor.endsWith("-deep")) {
  //   simpleZoneColor = simpleZoneColor.split("-deep")[0]
  //   extraStyle = {
  //
  //   }
  // }
  return <ReactSVG
    src={getNodeIcon(simpleZoneColor)}
    style={{
      fill: getNodeColor(simpleZoneColor),
      stroke: "white",
      strokeWidth: 0.5,
      // ...extraStyle
    }}
  />
}

export const getConnectionIcon = (portalType: PortalType): ReactElement => {
  return <ReactSVG
    src={LineFilledIcon}
    style={{
      fill: getEdgeColor(portalType),
    }}
  />
}