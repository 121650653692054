import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ArrowRotateLeftIcon,
  PenToSquareIcon,
  PlusIcon,
  TimerIcon
} from "assets";
import StyledSelect from "sharedComponents/StyledSelect";
import StyledButton from "sharedComponents/StyledButton";
import JustText from "sharedComponents/JustText";
import StyledBlock from "sharedComponents/StyledBlock";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  createPortalConnection,
  PortalType, setCenterOnZone,
  setSelectedFullZone, setSelectedPortalConnection,
  updatePortalConnection
} from "redux/data/portalMapSlice";
import { SimpleZone } from "redux/data/simpleZoneSlice";
import dayjs from "dayjs";
import { getConnectionIcon } from "pages/MainPage/components/PortalMapComponent/styleUtils";
import { reloadPortalerData } from "App";
import { ZoneSelect } from "sharedComponents/ZoneSelect";
import { NumberSelect } from "sharedComponents/NumberSelect";
import styles from "./style.module.scss";
import { BaseSelectRef } from "rc-select/lib/BaseSelect";
import { FullZone } from "redux/data/fullZoneSlice";

const NewConnectionComponent = () => {
  const [from, setFrom] = useState<SimpleZone>();
  const [to, setTo] = useState<SimpleZone>();
  const [portalType, setPortalType] = useState<PortalType>("BLUE");
  const [hours, setHours] = useState<number>();
  const [minutes, setMinutes] = useState<number>();
  const [lastAddedZone, setLastAddedZone] = useState<FullZone | undefined>(undefined)

  const portalMap = useAppSelector(state => state.portalMap.selectedPortalMap);
  const selectedPortalConnection = useAppSelector(state => state.portalMap.selectedPortalConnection);
  const selectedFullZone = useAppSelector(state => state.portalMap.selectedFullZone);
  const fullZones = useAppSelector(state => state.fullZone.fullZones);
  const portalerUserMe = useAppSelector(state => state.userInfo.user);

  const dispatch = useAppDispatch();

  const [timeUTC, setTimeUTC] = useState(new Date())

  const fromZoneInputRef = useRef<BaseSelectRef | undefined>(undefined);
  const focusFromZoneInput = useCallback(() => {
    fromZoneInputRef.current?.focus();
  }, [fromZoneInputRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      dayjs().utcOffset()
      setTimeUTC(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const expiringDateToHoursAndMinutes = (expiringDate: Date): [number, number] => {
    const dateDiff = -dayjs().diff(expiringDate)
    return [~~(dateDiff / 1000 / 60 / 60), ~~(dateDiff / 1000 / 60 % 60)]
  }

  const hoursAndMinutesToExpiringDate = (hours: number, minutes: number): Date => {
    return dayjs().add(hours, "hours").add(minutes, "minutes").toDate()
  }

  useEffect(() => {
    if (selectedPortalConnection) {
      setFrom(selectedPortalConnection.info.fromZone)
      setTo(selectedPortalConnection.info.toZone)
      setPortalType(selectedPortalConnection.info.portalType)
      if (!selectedPortalConnection.info.portalType.startsWith("STATIC")) {
        const [hours, minutes] = expiringDateToHoursAndMinutes(selectedPortalConnection.info.expiringDate!!)
        setHours(hours)
        setMinutes(minutes)
      } else {
        setHours(undefined)
        setMinutes(undefined)
      }
    } else {
      setFrom(undefined);
      setTo(undefined);
      setPortalType("BLUE");
      setHours(undefined);
      setMinutes(undefined);
    }
    if (selectedFullZone) {
      setFrom(selectedFullZone)
    }
  }, [selectedPortalConnection, selectedFullZone])


  if (!portalMap || !portalerUserMe) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
        <JustText textSize={"big"} isBold={true}>Add Connection</JustText>
        <div style={{ textAlign: "center" }}>
          <JustText textSize={"big"} isBold={false}>No map selected</JustText>
        </div>
      </div>
    );
  } else if (!portalerUserMe.roles.some(value => portalMap.accessRoles.EDIT.includes(value))) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
        <JustText textSize={"big"} isBold={true}>Add Connection</JustText>
        <div style={{ textAlign: "center" }}>
          <JustText textSize={"big"} isBold={false}>You don't have permission to edit map</JustText>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.newConnectionForm}>
        <JustText textSize={"big"} isBold={true}>Add Connection</JustText>
        <ZoneSelect
          reff={fromZoneInputRef}
          label={"From"}
          exclude={to ? [to] : []}
          value={from}
          onChange={value => setFrom(value)}
        />
        <ZoneSelect
          label={"To"}
          exclude={from ? [from] : []}
          value={to}
          onChange={value => setTo(value)}
        />
        <div style={{ display: "flex", gap: 6 }}>
          <NumberSelect
            min={0}
            max={23}
            label={"Hours"}
            value={hours}
            onChange={value => setHours(value)}
            disabled={portalType.startsWith("STATIC")}
          />
          <NumberSelect
            min={0}
            max={59}
            label={"Minutes"}
            value={minutes}
            onChange={value => setMinutes(value)}
            disabled={portalType.startsWith("STATIC")}
          />
        </div>
        <StyledSelect
          defaultHint={"From"}
          defaultValue={"BLUE"}
          value={portalType}
          data={[
            <StyledSelect.Entry value={"BLUE"} label={"7-Person"} icon={getConnectionIcon("BLUE")} />,
            <StyledSelect.Entry value={"YELLOW"} label={"20-Person"} icon={getConnectionIcon("YELLOW")} />,
            <StyledSelect.Entry value={"STATIC_TEMP"} label={"Static Temp"} icon={getConnectionIcon("STATIC_TEMP")} />,
            <StyledSelect.Entry value={"STATIC_CONST"} label={"Static Const"} icon={getConnectionIcon("STATIC_CONST")} />
          ]}
          onChange={value => {
            setPortalType(value as PortalType);
            if (value.startsWith("STATIC")) {
              setMinutes(undefined);
              setHours(undefined);
            }
          }}
        />
        {!selectedPortalConnection ?
          <StyledButton
            icon={PlusIcon}
            label={"Create connection"}
            disabled={!from || !to || (!portalType.startsWith("STATIC") && !hours && !minutes)}
            onClick={() => {
              if (from && to) {
                dispatch(createPortalConnection({
                  portalConnectionInfo: {
                    fromZone: from,
                    toZone: to,
                    portalType: portalType,
                    expiringDate: (portalType === "STATIC_TEMP" || portalType === "STATIC_CONST") && !hours && !minutes
                      ? undefined
                      : hoursAndMinutesToExpiringDate(hours ?? 0, minutes ?? 0)
                  },
                })).then(() => {
                  setLastAddedZone(fullZones.find(v => v.name === from.name))
                  setTo(undefined);
                  setHours(undefined);
                  setMinutes(undefined);
                  reloadPortalerData(dispatch);
                  focusFromZoneInput();
                });
              }
            }} />
          :
          <StyledButton
            icon={PenToSquareIcon}
            label={"Update connection"}
            disabled={!from || !to || (!portalType.startsWith("STATIC") && !hours && !minutes)}
            onClick={() => {
              if (from && to) {
                dispatch(updatePortalConnection({
                  portalId: selectedPortalConnection.portalId,
                  portalConnectionInfo: {
                    fromZone: from,
                    toZone: to,
                    portalType: portalType,
                    expiringDate: (portalType === "STATIC_TEMP" || portalType === "STATIC_CONST") && !hours && !minutes
                      ? undefined
                      : hoursAndMinutesToExpiringDate(hours ?? 0, minutes ?? 0)
                  },
                })).then(() => {
                  setTo(undefined);
                  setHours(undefined);
                  setMinutes(undefined);
                  dispatch(setSelectedPortalConnection(undefined))
                  reloadPortalerData(dispatch)
                  focusFromZoneInput();
                });
              }
            }} />
        }
        <StyledButton
          icon={ArrowRotateLeftIcon}
          label={"Go to last added zone"}
          onClick={() => dispatch(setCenterOnZone(lastAddedZone))}
          disabled={!lastAddedZone}
        />

        <StyledBlock groupsOfElements={[[TimerIcon, "UTC"], [
          `${timeUTC.getUTCHours() < 10 ? "0" + timeUTC.getUTCHours() : timeUTC.getUTCHours()}`
          + ":" +
          `${timeUTC.getUTCMinutes() < 10 ? "0" + timeUTC.getUTCMinutes() : timeUTC.getUTCMinutes()}`
          + ":" +
          `${timeUTC.getUTCSeconds() < 10 ? "0" + timeUTC.getUTCSeconds() : timeUTC.getUTCSeconds()}`
        ]]} />
      </div>
    );
  }
};

export default NewConnectionComponent;
