import React, { ReactElement } from "react";
import styles from "sharedComponents/JustText/style.module.scss"

const JustText = (props: Props) => {
	const fontSize = props.textSize === "big" ? 20 : props.textSize === "medium" ? 16 : 12
	const fontWeight = props.isBold ? 600 : 400
	return (
		<div style={{
			display: "flex",
			gap: 6,
		}}>
			{props.prefixIcon}
			<div
				style={{
					fontSize: fontSize,
					fontWeight: fontWeight,
					margin: props.textSize === "big" ? `15px 5px 5px 5px` : 0
				}}
				className={styles.text}>
				{props.children}
			</div>
		</div>
	)
}

export default JustText

type Props = {
	prefixIcon?: ReactElement
	children?: string
	isBold?: boolean
	textSize?: TextSize
}

type TextSize = "big" | "medium" | "small"
