import React from "react";
import StyledSelect from "sharedComponents/StyledSelect";
import { Input } from "antd";
import StyledInput from "sharedComponents/StyledInput";


export const NumberSelect = (props: NumberSelectProps) => {
  return <StyledInput
    label={props.label}
    value={props.value?.toString()}
    onChange={value => {
      const number = Number(value === "" ? undefined : value);
      props.onChange?.(Number.isNaN(number) ? undefined : Math.max(props.min, Math.min(props.max, number)));
    }}
    disabled={props.disabled}
  />;
};

type NumberSelectProps = {
  label?: string
  value?: number
  onChange?: (value: number | undefined) => void
  min: number
  max: number
  disabled?: boolean
}