import React from "react";
import { Button, ConfigProvider } from "antd";

//TODO rewrite
const StyledButton = (props: Props) => {
  return (
    //TODO remove theme shit
    <ConfigProvider theme={{
      components: {
        Button: {
          colorPrimary: "#9C2020",
          colorPrimaryHover: "#B42121",
          colorPrimaryActive: "#d72b2b",
          colorBgContainerDisabled: "rgba(91, 97, 110, 0.30)",
          colorBorder: "rgba(91, 97, 110, 0.30)"
        }
      }
    }}>
      <Button
        type={"primary"}
        //TODO rewrite for icons to be equal size
        icon={props.icon ? <img src={props.icon} alt=""/> : null}
        onClick={props.onClick}
        disabled={props.disabled}
        style={props.headerButton ? {
          minWidth: 52,
          minHeight: 52,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#18191C",
          borderRadius: 4
        } : {
          minHeight: 52,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: props.centerContent ? "center" : "normal",
          backgroundColor: props.backgroundColor,
        }}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.label}
      </Button>
    </ConfigProvider>
  );
};

export default StyledButton;

type Props = {
  icon?: string
  label?: string
  disabled?: boolean
  onClick?: () => void

  //Styling
  centerContent?: boolean
  backgroundColor?: string

  //Used for top buttons
  headerButton?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}
