import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  appGlobalReducer,
  fullZoneReducer,
  portalerServerReducer,
  portalMapReducer,
  simpleZoneReducer,
  userInfoReducer
} from "redux/data";

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  portalerServer: portalerServerReducer,
  simpleZone: simpleZoneReducer,
  fullZone: fullZoneReducer,
  portalMap: portalMapReducer,
  userInfo: userInfoReducer,
  appGlobal: appGlobalReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
      immutableCheck: false
    })
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch