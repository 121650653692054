import React from "react";

import JustText from "sharedComponents/JustText";
import StyledBlock from "sharedComponents/StyledBlock";
import { ExclamationCircleIcon } from "assets";

const PathfindingComponent = () => {
  return (
    <div>
      <div style={{ display: "flex", flex: "1 0", flexDirection: "column" }}>
        <JustText textSize={"big"} isBold={true}>Pathfinding</JustText>
        <StyledBlock groupsOfElements={[[ExclamationCircleIcon, "Still Work In Progress"]]} alternativeStyle/>
      </div>
    </div>
  );
};

export default PathfindingComponent;
