import JustText from "sharedComponents/JustText";
import styles from "./style.module.scss"
import React, { JSX, ReactElement, ReactNode } from "react";

const StyledBlock = (props: Props) => {
  return <div
    className={styles.styledBlockWrapper}
    style={{ backgroundColor: props.alternativeStyle ? "#9C2020" : "#262729"}}
  >
    {props.groupsOfElements.map((group, groupIDX) => {
      return <div key={`styledBlock-group-${groupIDX}`} style={{display: "flex", gap: 6}}>
        {group.map((element, elementIDX) => {
          if (React.isValidElement(element)) {
            return <React.Fragment key={`styledBlock-element-${elementIDX}`}>{element}</React.Fragment>;
          }
          if (element.includes("/static/")) {
            return <img key={`styledBlock-element-${elementIDX}`} src={element} alt="" />;
          }
          return <JustText key={`styledBlock-element-${elementIDX}`} textSize={"medium"}>{element}</JustText>;
        })}
      </div>
    })}
  </div>
}

type Props = {
  groupsOfElements: (string | ReactElement)[][]
  alternativeStyle?: boolean
}

export default StyledBlock