import React from "react";
import styles from "sharedComponents/FooterComponent/style.module.scss";
import { BoostyLogoIcon, DiscordLogoIcon, GithubLogoIcon, PatreonLogoIcon } from "assets";

const FooterComponent = () => {
  return (
    <div className={styles.background}>
      <div className={styles.footerInner}>
        <div className={styles.innerStyles}>
          <img src={PatreonLogoIcon} style={{ marginRight: "15px", cursor: "pointer" }} alt="" onClick={
            () => window.open("https://www.patreon.com/aportaler/membership", "_blank")
          } />
          <img src={BoostyLogoIcon} style={{ marginRight: "15px", cursor: "pointer" }} alt="" onClick={
            () => window.open("https://boosty.to/aaur1s", "_blank")
          } />
          <div style={{ width: "60%" }}>
            You can get private portaler for your guild from our Patreon/Boosty.
          </div>
        </div>

        <div className={styles.innerStyles}>
          <img src={DiscordLogoIcon} style={{ marginRight: "20px", cursor: "pointer" }} alt="" onClick={
            () => window.open("https://discord.gg/hbupFJ3AAz", "_blank")
          } />
          <img src={GithubLogoIcon} style={{ cursor: "pointer" }} alt="" onClick={
            () => window.open("https://github.com/Aaur1s", "_blank")
          } />
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
